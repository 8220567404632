const Menu01 = ({ active, className }) => {
  if (active) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 112 112"
        fill="none"
        className={className}
      >
        <path
          d="M92.03 49.43 57.16 19.87c-.38-.33-.94-.33-1.32 0L20.97 49.43c-.69.61-.26 1.75.66 1.75h8.5v34.19c0 3.87 3.13 7 7 7h38.74c3.87 0 7-3.13 7-7V51.18h8.5c.92 0 1.35-1.14.66-1.75ZM66.94 71.67c0 .55-.45 1-1 1H47.05c-.55 0-1-.45-1-1V52.78c0-.55.45-1 1-1h18.89c.55 0 1 .45 1 1v18.89Z"
          fill="#e2ea7b"
          stroke="#000"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={4}
        />
      </svg>
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 112" fill="none" className={className}>
      <path
        d="M92.03 49.43 57.16 19.87c-.38-.33-.94-.33-1.32 0L20.97 49.43c-.69.61-.26 1.75.66 1.75h8.5v34.19c0 3.87 3.13 7 7 7h38.74c3.87 0 7-3.13 7-7V51.18h8.5c.92 0 1.35-1.14.66-1.75ZM66.94 71.67c0 .55-.45 1-1 1H47.05c-.55 0-1-.45-1-1V52.78c0-.55.45-1 1-1h18.89c.55 0 1 .45 1 1v18.89Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
    </svg>
  );
};

const Menu02 = ({ active, className }) => {
  return active ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 112" className={className}>
      <path
        d="M46.02 20.6h0c5.51 0 9.98 4.47 9.98 9.98v18.57H36.05V30.58c0-5.51 4.47-9.98 9.98-9.98ZM65.98 20.6h0c5.51 0 9.98 4.47 9.98 9.98v18.57H56.01V30.58c0-5.51 4.47-9.98 9.98-9.98Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M25.63 53.7h60.74v30.7c0 3.86-3.14 7-7 7H32.63c-3.86 0-7-3.14-7-7V53.7h0Z"
        fill="#e2ea7b"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <rect
        width="72.89"
        height="18.84"
        x="19.56"
        y="34.87"
        fill="#e2ea7b"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={4}
        rx={1}
        ry={1}
      />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 112" className={className}>
      <path
        d="M46.02 20.6h0c5.51 0 9.98 4.47 9.98 9.98v4.3H36.05v-4.3c0-5.51 4.47-9.98 9.98-9.98ZM65.98 20.6h0c5.51 0 9.98 4.47 9.98 9.98v4.3H56.01v-4.3c0-5.51 4.47-9.98 9.98-9.98ZM25.63 53.7h60.74v30.7c0 3.86-3.14 7-7 7H32.63c-3.86 0-7-3.14-7-7V53.7h0Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <rect
        width="72.89"
        height="18.84"
        x="19.56"
        y="34.87"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={4}
        rx={1}
        ry={1}
      />
      <path
        d="M56 53.7v36.65"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
    </svg>
  );
};

const Menu03 = ({ active, className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 112" className={className}>
      <path
        d="M92 35.59c0-.92-1.13-1.35-1.74-.67L74.45 52.39a.999.999 0 0 1-1.58-.13L56.82 27.62a1 1 0 0 0-1.68 0L39.11 52.24c-.36.55-1.14.61-1.58.13L21.74 34.93c-.61-.68-1.74-.24-1.74.67v42.26c0 3.87 3.13 7 7 7h57.98c3.87 0 7-3.13 7-7v-9.73c.02-.08.03-.15.03-.24V35.6Z"
        fill={active ? '#e2ea7b' : 'none'}
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
    </svg>
  );
};

const Menu04 = ({ active, className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 112" className={className}>
      <circle
        cx="56"
        cy="56"
        r="34.35"
        fill={active ? '#e2ea7b' : 'none'}
        stroke="#000"
        strokeLinecap="round"
        strokeWidth={4}
        strokeMiterlimit={10}
      />
      <circle cx="39.65" cy="47.88" r="3.05" />
      <circle cx="71.88" cy="47.88" r="3.05" />
      <path
        d="M55.11 48.75 49 60.86l4.92 3.92"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
      />
    </svg>
  );
};

const routeData = [
  {
    label: '필링',
    path: '/', // /philing
    icon: {
      default: 'https://images.odoctor.co.kr/philing/icons/menu_01_default.png',
      active: 'https://images.odoctor.co.kr/philing/icons/menu_01_selected.png',
      svgDrawFunc: Menu01,
    },
    badge: false,
  },
  {
    label: '픽',
    path: '/pick',
    icon: {
      default: 'https://images.odoctor.co.kr/philing/icons/menu_02_default.png',
      active: 'https://images.odoctor.co.kr/philing/icons/menu_02_selected.png',
      svgDrawFunc: Menu02,
    },
    badge: false,
  },
  {
    label: '라운지',
    path: '/community',
    icon: {
      default: 'https://images.odoctor.co.kr/philing/icons/menu_03_default.png',
      active: 'https://images.odoctor.co.kr/philing/icons/menu_03_selected.png',
      svgDrawFunc: Menu03,
    },
    badge: true,
  },
  {
    label: '마이',
    path: '/community/my',
    icon: {
      default: 'https://images.odoctor.co.kr/philing/icons/menu_04_default.png',
      active: 'https://images.odoctor.co.kr/philing/icons/menu_04_selected.png',
      svgDrawFunc: Menu04,
    },
    badge: false,
  },
];

export default routeData;
