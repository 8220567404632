import { gql } from '@apollo/client';

export const GET_SIGN_URL = gql`
  mutation ($filename: String!, $bucket: String, $filetype: String = "image") {
    signS3(filename: $filename, filetype: $filetype, bucket: $bucket) {
      signedRequest
      url
    }
  }
`;

export const GET_DOWNLOAD_CONTENTS = gql`
  query {
    downloadPdfs
  }
`;

export const GET_DOWNLOAD_CONTENT = gql`
  query ($id: ID!) {
    downloadPdfById(id: $id)
  }
`;

export const GET_SIGNURL_DOWNLOAD_CONTENT = gql`
  mutation ($bucket: String, $filename: String!, $id: ID!) {
    downloadPdf(bucket: $bucket, filename: $filename, id: $id, filetype: "pdf") {
      signedRequest
      url
    }
  }
`;
