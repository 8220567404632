import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import routeData from 'config/routeData';

// 개별 네비게이션 아이템
const NavItem = ({ label, path, icon, badge, isActive }) => (
  <li className="nav-item">
    <Link href={path} prefetch={false} passHref>
      <a className="nav-link no-underline text-gray-600" aria-label={label}>
        <TabWrap>
          {badge && <RedDot />}
          {icon.svgDrawFunc({ active: isActive, className: 'w-[3rem] h-[3rem]' })}
          <span className="nav-label text-gray-600 font-bold text-base">{label}</span>
        </TabWrap>
      </a>
    </Link>
  </li>
);

export default function BottomNavMenu() {
  const router = useRouter();
  const { pathname } = router;

  const isActiveRoute = (path) => (path === '/' ? pathname === '/' : pathname.startsWith(path));

  return (
    <Navigator aria-label="메인 네비게이션">
      <ul className="nav-list flex justify-around">
        {routeData.map(({ label, path, icon, badge }) => (
          <NavItem
            key={path}
            label={label}
            path={path}
            icon={icon}
            badge={badge}
            isActive={isActiveRoute(path)}
          />
        ))}
      </ul>
    </Navigator>
  );
}
const RedDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Navigator = styled.nav`
  position: fixed;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  margin-left: -1.4rem;
  left: auto;
  bottom: 0;
  z-index: 99;
  border-top: 1px solid #bbbbbb;
  background-color: #fff;
`;

const TabWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 0;
  cursor: pointer;
  opacity: ${({ disabled }) => disabled && '0.2'};
  width: 5rem;
`;
